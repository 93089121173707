body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #B5B5B5 !important;
}

a, body, button, html {
  cursor: none !important;
}

*:hover {
  cursor: none !important;
}

//Scroll bars
::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #ffffff1a;
}
::-webkit-scrollbar {
	width: 8px;
  height: 8px;
	// background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #ffffff61;
}