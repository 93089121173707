@import './variables.module.scss';

.flex_center {
    @include flexbox($display: $flex, $justifyContent: center, $alignItems: center);
}

.radial_circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    top: 30vh;
    left: 15vw;
    background: radial-gradient(50% 50% at 50% 50%, rgba(223, 25, 255, 0.39) 0%, rgba(223, 25, 255, 0) 100%);
    opacity: 0.4;
    animation: movecircle 18s ease-in-out infinite;
}
  
@keyframes movecircle {
    0% {
      transform: translate(-40px, -40px);
    }
    50% {
      transform: translate(240px, 190px);
    }
    100% {
        transform: translate(-40px, -40px);
    }
}

.radial_circle_top {
    position: absolute;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    top: -80px;
    right: -40px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(25, 75, 255, 0.46) 0%, rgba(25, 75, 255, 0) 100%);
    opacity: 0.4;
    animation: movecircle 18s ease-in-out infinite;
}

.radial_circle_footer {
    position: absolute;
    width: 500px;
    height: 180px;
    border-radius: 50%;
    left: 35%;
    opacity: 0.4;
    background: radial-gradient(50% 50% at 50% 50%, rgba(25, 75, 255, 0.46) 0%, rgba(25, 75, 255, 0) 100%);
}

.header_section {
    @include flexbox($display: $flex, $justifyContent: space-between, $alignItems: center);
    @include padding($rectangle: 30px 110px);
    @include transition;
    position: fixed;
    border-radius: 100px;
    top: 20px;
    width: 100%;
    z-index: 99;
    .social_icons {
        @include flexbox($display: $flex, $justifyContent: space-between, $alignItems: center, $columnGap: 40px);
        img {
            height: 18px;
            cursor: pointer;
            opacity: 0.7;
        }
    }
    .name_logo {
        @include typography($weight: 6, $size: 30px);
        letter-spacing: 3px;
    }
    .header_left {
        @include flexbox($display: $flex, $justifyContent: space-between, $alignItems: center, $columnGap: 40px);
        span {
            @include typography($size: 18px);
        }
    }
}
.header_small {
    width: 1080px;
    background: rgba(71, 71, 71, 0.4);
    backdrop-filter: blur(8px);
    @include transition;
}

.center_text {
    text-align: center;
}

.header_link {
    margin-right: 30px;
    text-decoration: none;
    color: black;
    transition: all 0.3s;
    position: relative;
    padding: 6px 0px;

    &:hover {
        color: black;
        text-decoration: none;
    }

    &::after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        background: #000;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
    }

    &:hover::after {
        width: 100%;
        left: 0;
    }
}

.banner_section {
    @include padding($rectangle: 40px 60px);
    @include margin($top: 160px);
    text-align: center;
    .hello_text {
        @include typography($weight: 5, $size: 56px);
        width: max-content;
        color: #f8f8f8d3;
        @include margin($left: auto, $right: auto, $bottom: 40px);
    }
    .text-transition_inner div {
        background: linear-gradient(98.18deg, rgba(248, 248, 248, 0.86) -0.03%, rgba(248, 248, 248, 0.48) 47.32%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    .about_description {
        @include margin($top: 90px);
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        @include typography($size: 22px, $height: 42px, $weight: 5);
        background: linear-gradient(98.18deg, #F8F8F8 -0.03%, rgba(248, 248, 248, 0.57) 47.32%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        img {
            height: 100px;
            @include margin($bottom: 50px);
            border-radius: 100px;
        }
    }
}

.mobile_UI_icons {
    display: none;
}

.flex_apart {
    @include flexbox($display: $flex, $justifyContent: space-between, $alignItems: start);
}

.underline {
    // background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
    &:hover {
      background-size: 100% 40%;
    }
}

.scroll_box{
	display: block;
	width: 20px;
	height: 20px;
	border-bottom: 2px solid #fff;
	border-right: 2px solid #fff;
	transform: rotate(45deg);
	margin: -10px;
	animation: animate 2s infinite;
}

@keyframes animate{
	0%{
		opacity: 0;
		transform: rotate(45deg) translate(-20px, -20px);
	}
	50%{
		opacity: 1;
	}
	100%{
		opacity: 0;
		transform: rotate(45deg) translate(20px, 20px);
	}
}

.scroll_container {
    @include flexbox($display: $flex, $justifyContent: center, $alignItems: center);
    @include margin($top: 80px);
}

//Design
.design_container {
    @include margin($top: 200px);
    .padded_container {
        @include padding($rectangle: 0px 60px);
    }
    .design_title {
        text-align: center;
        font-family: 'VT323', monospace;
        @include typography($size: 54px, $weight: 6, $color: #cacaca);
    }
    .design_slider {
        @include flexbox($display: $flex, $alignItems: center, $columnGap: 40px);
        @include margin($top: 80px);
        .cardLight {
            @include padding($rectangle: 26px);
            background: #161b22;
            // border: 1px solid #30363d;
            border-radius: 18px;
            .design_thumb {
                width: 580px;
                object-fit: cover;
                height: 350px;
                border-radius: 18px;
                @include margin($bottom: 30px);
                opacity: 0.9;
            }
            .title {
                @include typography($size: 19px, $weight: 6, $color: $darkGrey);
            }
            .desc {
                @include typography($size: 17px, $color: $darkGrey2);
            }
        }
    }
    .button_minimal {
        @include flexbox($display: $flex, $alignItems: center, $justifyContent: center, $columnGap: 10px);
        @include padding($rectangle: 18px 40px);
        @include typography($size: 18px, $weight: 6, $color: $darkGrey);
        border: 2px solid $darkGrey2;
        width: fit-content;
        border-radius: 100px;
        @include transition;
        box-shadow: none;
        @include margin($rectangle: 80px auto);
        img {
            height: 26px;
        }
        &:hover {
            box-shadow: 6px 6px #316dd1;
            @include transition;
        }
    }
}

.scroll_head {
    @include typography($size: 110px, $weight: 8);
    @include flexbox($display: $flex, $alignItems: center, $columnGap: 40px);
    span {
        color: #202833;
    }
}

@mixin glitchCopy { 
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glitch {
    position: relative;
    @include typography($size: 28px);
    &::before{
        @include glitchCopy;
        left: 2px;
        text-shadow: -2px 0 #ff00c1;
        clip: rect(44px, 450px, 56px, 0);
        animation: glitch-anim 5s infinite linear alternate-reverse;
    }

    &::after {
        @include glitchCopy;
        left: -2px;
        text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
        animation: glitch-anim2 1s infinite linear alternate-reverse;
    }
}

@keyframes glitch-anim {
    $steps: 20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
            clip: rect(random(100)+px, 9999px, random(100)+px, 0);
            transform: skew((random(100) / 100) + deg);
        }
    }
}

@keyframes glitch-anim2 {
    $steps: 20;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
        clip: rect(random(100)+px, 9999px, random(100)+px, 0);
                transform: skew((random(100) / 100) + deg);
        }
    }
}

.code_container {
    @include margin($top: 200px);
    @include padding($rectangle: 60px 90px);
    .code_img {
        border-radius: 20px;
        @include margin($top: 46px);
        height: 370px;
        box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.25);
        left: 160px;
        position: absolute;
        z-index: 2;
    }
    .code_title {
        @include typography($size: 22px, $weight: 6, $color: #e6e6e6);
    }
    .code_sub_text {
        @include margin($top: 16px);
        @include typography($size: 16px, $color: #e6e6e6, $height: 27px, $weight: 3);
    }
    .code_columns {
        @include gridbox($display: $grid, $justifyContent: space-between, $template: 1.5fr 2fr, $columnGap: 40px, $rowGap: 40px);
        .gridSpace {
            @include flexbox($display: $flex, $justifyContent: space-between, $direction: column);
        }
        .code_card {
            border-radius: 20px;
            @include padding($rectangle: 38px 43px);
            overflow: hidden;
            position: relative;
            height: fit-content;
            .flex_apart {
                column-gap: 12px;
            }
            .arrow_right {
                height: 26px;
                opacity: 0.9;
                transform: rotate(0deg);
                @include transition;
            }
            .code_img {
                transform: translateX(0px);
                opacity: 0.9;
                @include transition;
            }
            &:hover {
                .code_img {
                    transform: translateX(-50px);
                    opacity: 1;
                    @include transition;
                }
                .arrow_right {
                    transform: rotate(45deg);
                    @include transition;
                }
            }
            .coming_soon {
                @include flexbox($display: $flex, $columnGap: 6px, $alignItems: center, $justifyContent: center);
                @include margin($top: 30px);
                background: #ffffff3b;
                width: fit-content;
                border-radius: 100px;
                @include padding($rectangle: 12px 30px);
                @include typography($size: 14px, $weight: 6, $color: #f8f8f8);
                img {
                    height: 18px;
                    position: relative;
                    margin-top: 0px;
                    left: auto;
                    box-shadow: none;
                }
            }
        }
        .code_card1 {
            @include padding($rectangle: 24px 34px);
            @include margin($bottom: 20px);
            background: radial-gradient(31.14% 79.93% at 20.94% 50.35%, #220241 0%, rgba(34, 2, 65, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(23.79% 61.07% at 80.81% 85.82%, rgba(2, 26, 65, 0.6) 0%, rgba(0, 45, 118, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
            border: 2px solid #2E2E2E;
            border-radius: 20px;
            div {
                opacity: 0.8;
            }
            span {
                @include typography($color: #3985FF, $weight: 8);
            }
            @include typography($size: 24px, $weight: 6);
            .sub_text {
                @include typography($size: 18px, $weight: 4);
                @include margin($top: 16px);
            }
        }
        .code_card2 {
            background: linear-gradient(147.58deg, #33466C 3.38%, #4C336C 80.58%);
            height: 520px;
        }
        .code_card3 {
            background: linear-gradient(146.21deg, #336081 3.21%, #334E81 79.96%);
            height: 100%;
            img {
                height: 570px;
            }
        }
        .code_card4 {
            opacity: 0.8;
            background: linear-gradient(126.18deg, #006E75 4.62%, #003675 78.65%);
        }
        .code_card5 {
            opacity: 0.8;
            background: linear-gradient(126.18deg, #600c5b 4.62%, #41015a 78.65%);
        }
    }
}

.blog_container {
    @include margin($top: 230px);
    .blog_slider {
        @include margin($top: 50px);
        @include flexbox($display: $flex, $alignItems: center, $columnGap: 100px);
        @include padding($rectangle: 60px 90px);
        .blog_card {
            @include padding($rectangle: 28px);
            background: #161b22;
            border-radius: 18px;
            max-width: 450px;
            border: 1px solid #2b3138;
            &:hover {
                .read_more {
                    opacity: 1;
                    @include transition;
                    img {
                        height: 24px;
                        transform: rotate(45deg);
                        @include transition;
                    }
                }
            }
            .blog_title {
                @include typography($size: 22px, $weight: 6, $color: #C8C8C8);
            }
            .blog_desc {
                @include typography($size: 16px, $color: #838383, $height: 26px);
                @include padding($top: 16px);
            }
            .read_more {
                @include flexbox($display: $flex, $alignItems: center, $columnGap: 16px);
                @include typography($size: 18px, $color: #fff);
                @include margin($top: 40px);
                @include transition;
                opacity: 0.7;
                img {
                    height: 18px;
                    transform: rotate(0deg);
                    @include transition;
                }
            }
        }
    }
}

.review_container {
    @include padding($rectangle: 60px 90px);
    @include margin($rectangle: 90px auto);
    .review_head {
        @include flexbox($display: $flex, $alignItems: center, $justifyContent: center, $direction: column);
        @include margin($bottom: 160px);
        .review_header {
            @include margin($bottom: 40px);
            @include typography($size: 60px, $weight: 8, $color: #fff);
            opacity: 0.2;
        }
        .review_subhead {
            @include margin($top: 40px);
            @include typography($size: 22px, $color: #B5B5B5);
        }
    }
    .review_slider {
        @include margin($rectangle: 50px auto);
        @include flexbox($display: $flex, $alignItems: center, $justifyContent: space-between, $columnGap: 60px);
        width: 62vw;
        .review_item {
            @include flexbox($display: $flex, $alignItems: center, $justifyContent: space-between);
            height: 190px;
            #review_footer:hover ~ #review_img {
                filter: none;
            }
            .review_content {
                @include margin($right: -110px, $top: 60px);
                width: 730px;
                z-index: 2;
                .review_name {
                    width: fit-content;
                    @include flexbox($display: $flex, $alignItems: center, $justifyContent: center, $columnGap: 20px);
                    @include typography($size: 22px, $color: #ffffff, $weight: 7);
                    opacity: 0.6;
                    img {
                        height: 24px;
                        width: 24px;
                        @include transition;
                        transform: rotate(0deg);
                    }
                }
                .review_company {
                    @include typography($size: 16px, $color: #ffffff, $weight: 2);
                    @include margin($top: 8px);
                    opacity: 0.6;
                }
                .review_footer {
                    @include flexbox($display: $flex, $alignItems: flex-start, $justifyContent: space-between, $columnGap: 20px);
                    width: 780px;
                    .review_footer_left:hover {
                        img {
                            transform: rotate(45deg);
                            opacity: 1;
                            @include transition;
                        }
                    }
                }
                .slider_arrow {
                    @include flexbox($display: $flex, $alignItems: flex-start, $justifyContent: start, $columnGap: 20px);
                    @include margin($right: 140px);
                    img {
                        height: 30px;
                        width: 30px;
                        &:last-child {
                            transform: rotate(180deg);
                        }
                        opacity: 0.7;
                    }
                }
            }
            .review_img {
                height: 380px;
                width: 300px;
                object-fit: cover;
                // filter: grayscale(1);
                transition: filter 0.3s ease-in;
                opacity: 0.5;
                &:hover {
                    filter: none;
                    transition: filter 0.3s ease-in;
                }
            }
            .remove_grayscale {
                filter: none;
                transition: filter 0.3s ease-in;
            }
            p {
                @include typography($size: 20px, $color: #ffffff, $height: 30px);
                opacity: 0.7;
                @include margin($bottom: 40px);
                height: 180px;
            }
        }
    }
}

.footer_container {
    @include padding($rectangle: 30px 0px);
    @include margin($top: 140px, $left: 90px, $right: 90px);
    border-top: 2px solid #ffffff27;
    @include flexbox($display: $flex, $alignItems: center, $justifyContent: space-between, $columnGap: 20px);
    @include typography($size: 18px, $color: #ffffff52);
}

.text_header {
    @include typography($weight: 6, $size: 40px, $height: 50px); 
}
.text_content {
    @include typography($size: 18px, $height: 30px);
    div {
        @include typography($weight: 6);
    }
    p {
        @include typography($size: 16px, $height: 24px, $weight: 4);
        @include margin($top: 10px);
    }
}
.text_quote {
    @include typography($size: 18px, $height: 26px);
    font-style: italic;
}

.footer {
    text-align: center;
    @include margin($top: 40px);
    width: 100%;
    @include padding($bottom: 20px);
}

.image_link {
    position: relative;
    @include flexbox($display: $flex, $justifyContent: center, $alignItems: center);
    @include transition;
    .hover_content {
        @include flexbox($display: $flex, $justifyContent: center, $alignItems: center, $direction: column);
        position: absolute;
        z-index: 1;
        opacity: 0;
        @include transition;
        img {
            height: 30px;
            transform: rotate(0deg);
            @include transition;
        }
    }
    .hover_text {
        @include typography($size: 18px, $weight: 4, $color: rgba(255, 255, 255, 0.8));
    }
    img {
        filter: brightness(1);
        @include transition;
    }
    &:hover {
        img {
            filter: brightness(0.5);
            @include transition;
        }
        .hover_content {
            opacity: 1;
            @include transition;
            img {
                filter: brightness(0.8);
                transform: rotate(140deg);
                @include transition;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    html {
        overflow-x: hidden;
    }
    .hide_mobile {
        display: none;
    }
    body {
        text-align: center;
    }
    .header_section {
        @include padding($rectangle: 20px 20px);
        .social_icons {
            column-gap: 20px;
        }
    }
    .glitch {
        line-height: 40px;
    }
    .header_small {
        width: 90%;
        @include margin($rectangle: 0px 20px);
        @include transition;
    }
    .banner_section {
        @include padding($top: 40px, $left: 24px, $right: 24px);
        .hello_text {
            @include typography($size: 40px);
            @include margin($bottom: 0px);
        }
        .about_description {
            @include margin($top: 70px);
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            @include typography($size: 20px, $height: 36px);
            img {
                height: 100px;
                @include margin($bottom: 30px);
                border-radius: 100px;
            }
        }
    }
    .design_container {
        @include margin($top: 90px);
        .design_slider {
            @include margin($left: 10px !important);
            .cardLight {
                .design_thumb {
                    width: auto;
                    height: 300px;
                    border-radius: 14px;
                }
            }
        }
        .design_title {
            @include typography($size: 40px, $height: 40px);
        }
        .button_minimal {
            @include padding($rectangle: 14px 30px);
            @include margin($rectangle: 50px 20px);
            @include typography($size: 17px, $height: 25px);
        }
    }
    .code_container {
        // @include gridbox($display: $grid, $justifyContent: space-between, $template: 1.5fr 2fr, $columnGap: 40px, $rowGap: 40px);
        @include padding($rectangle: 60px 30px);
        @include margin($top: 0px);
        .code_img {
            width: 100%;
            left: auto;
            position: relative;
            height: 220px;
            object-fit: cover;
            @include margin($top: 20px);
            border-radius: 10px;
        }
        .code_title {
            @include typography($size: 22px);
        }
        .code_sub_text {
            @include typography($size: 16px, $height: 22px);
        }
        .code_columns {
            grid-template-columns: 1fr;
            .code_card {
                @include padding($rectangle: 20px 14px);
                @include flexbox($display: $flex, $justifyContent: center, $alignItems: center, $direction: column);
                &:hover {
                    .code_img {
                        transform: translateX(0px);
                    }
                }
            }
            .code_card2 {
                height: auto;
            }
            .code_card3 {
                img {
                    height: auto;
                }
            }
            .code_card4 {
                @include margin($bottom: 30px);
            }
            .arrow_right {
                display: none;
            }
        }
    }
    .blog_container {
        @include margin($top: 120px);
        .blog_slider {
            @include padding($rectangle: 30px);
            flex-direction: column;
            row-gap: 30px;
            .blog_card {
                .blog_title {
                    @include typography($height: 30px);
                }
                .read_more {
                    justify-content: center;
                    @include margin($top: 30px);
                }
            }
        }
    }
    .review_container {
        @include margin($rectangle: 40px auto);
        @include padding($rectangle: 20px);
        .review_slider {
            @include margin($all: 0px);
            .review_item {
                flex-direction: column-reverse;
                height: auto;
                p {
                    height: auto;
                    @include margin($bottom: 20px);
                    @include typography($height: 27px, $size: 17px);
                }
                @include margin($bottom: 20px);
                .review_content {
                    @include margin($top: 0px, $right: 0px);
                    width: 100%;
                    .review_footer {
                        @include flexbox($display: $flex, $alignItems: center, $justifyContent: center, $direction: column);
                        width: 100%;
                    }
                    .review_name {
                        width: auto;
                    }
                    .slider_arrow {
                        @include margin($top: 30px, $right: 0px);
                    }
                    .review_company {
                        @include typography($size: 16px, $weight: 4);
                    }
                }
                .review_img {
                    @include margin($bottom: 30px);
                }
            }
        }
        .review_head {
            @include margin($bottom: 60px);
            .review_header {
                @include typography($size: 50px, $height: 56px);
                @include margin($bottom: 0px);
            }
            .radial_circle_footer {
                left: auto;
            }
        }
        .review_slider {
            width: 100%;
        }
    }
    .footer_container {
        @include margin($top: 0px, $left: 20px, $right: 20px);
        @include padding($rectangle: 16px 0px);
        @include typography($size: 14px);
    }
}